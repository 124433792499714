import WarningBlock from "./components/WarningBlock";

const data = [
    {
        date: '7 November 2023',
        title: 'URGENT WARNING REGARDING RISING FRAUDULENT CERTIFICATES FROM JAPAN TO JAMAICA'
    },
    {
        date: '25th September 2023',
        title: 'Mandatory Pre-shipment Inspection for Used Vehicles Bound for Jamaica'
    },
    {
        date: '1st July 2020',
        title: 'Jamaica Tracking System and PSI Certificate with QR Code'
    }
];

const WarningsList = () => {
    const warningsList = data.map((warning, index) => {
        const { date, title } = warning;
        return <li key={index}>
            <WarningBlock date={date} title={title} />
        </li>
    });
    return <div className="warnings">
        <div className="container">
            <h2 className="title">Warning to the Importers/Exporters</h2>
            <ul className="warnings-list">
                {warningsList}
            </ul>
        </div>
    </div>
}

export default WarningsList;