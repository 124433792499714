import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home';
import WarningPage from './Warning';
import FirstWarning from './FirstWarning';
import SecondWarning from './SecondWarning';
import ThirdWarning from './ThirdWarning';
import AboutUs from './pages/AboutUs';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';

function App() {
  return (
    <div className="App">
      <BrowserRouter basename='/'>
        <Routes>
          <Route path='/' Component={Home} />
          <Route path='/about-us' Component={AboutUs} />
          <Route path='/privacy' Component={Privacy} />
          <Route path='/terms' Component={Terms} />
          <Route path='/warning' Component={WarningPage} />
          <Route path='/warning/1' Component={FirstWarning} />
          <Route path='/warning/2' Component={SecondWarning} />
          <Route path='/warning/3' Component={ThirdWarning} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
