import { Button, Col, Form, Input, Row } from 'antd';

const RequestInq = () => {
  return (
    <div className="container">
      <div className="request-inq">
        <Row justify="space-between" align="middle">
          <Col lg={14} md={13} sm={24} xs={24} className="content-wrapper">
            <p className="content">
              There are conformity assessment procedures conducted before shipment from the supply country which shall be used to verify that all used motor vehicles (including motorbikes, cars, vans, buses and trucks of all sizes) exported to Zambia, Jamaica and Kenya are in compliance with required standard. PSI/RWI results shall be determined by the compliance to the requirements specified in the standards.
            </p>
          </Col>
          <Col lg={8} md={9} sm={24} xs={24}>
            <h3 className="title">Request a Inquiry</h3>

            <Form name="form_item_path" layout="vertical">
              <Form.Item label="Full Name" name="firstName">
                <Input />
              </Form.Item>
              <Form.Item label="Email Address" name="email">
                <Input type="email" />
              </Form.Item>
              <Form.Item label="Contact Number" name="email">
                <Input type="email" />
              </Form.Item>

              <Button block type="primary" htmlType="submit">Submit</Button>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default RequestInq;