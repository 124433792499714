import LowerHeader from './LowerHeader';
import Footer from './Footer';
import PostThree from './PostThree';

const ThirdWarning = () => {
    return <>
        <LowerHeader />
        <PostThree />
        <Footer />
    </>
}

export default ThirdWarning;