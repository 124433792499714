import React from 'react'
import { Row, Col, Card, Timeline, Divider } from 'antd';
import data from '../../appData/aboutus.json';

export default function OurGoals() {
  const { ourGoals } = data;
  const goals = ourGoals.map((goalsSection) => {
    const goalPoints = goalsSection.map(goal => <li>{goal}</li>)

    return (
      <ul className='goals-list' style={{ width: '100%' }}>
        {goalPoints}
      </ul >
    )
  });
  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <h2 className='title'>Our Goals</h2>
          <Divider />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: '40px' }}>
        <Col span={24}>
          {goals}
        </Col>
      </Row>
    </>
  )
}