import React from 'react'
import { Row, Col, Card, Timeline, Divider } from 'antd';
import data from "../../appData/aboutus.json";

export default function History() {
  const { history } = data;

  const historyList = history.map(historyItem => {
    const { date, details } = historyItem;
    const detailsList = details.map(value => <p>{value}</p>);
    return (<Timeline.Item color="green">
      <>
        <p>
          <b>{date}</b>
        </p>
        {detailsList}
      </>
    </Timeline.Item>)
  });
  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <h2 className='title'>History</h2>
          <Divider />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Timeline>
            {historyList}
          </Timeline>
        </Col>
      </Row>
    </>
  )
}
