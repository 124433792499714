import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import NoticeImage from '../download-app-banner.png';

const DownloadApp = () => {
  const handleDownload = () => {
    document.getElementById("downloadLink").click();
  }
  return <section>
    <div className="container">
      <div className='download-app-note'>
        <img src={NoticeImage} alt='App download Notice Image' />
        <div className='buttons-block'>
          <a hidden id="downloadLink" href='/Autoterminal APP Guide.pdf' download target='_blank'>click</a>
          <Button onClick={handleDownload} className='download-guide-btn' type="primary" icon={<DownloadOutlined />} size="large">
            Get Started with Our Mobile App - Download Guide
          </Button>
        </div>
      </div>
    </div>
  </section>
}

export default DownloadApp;
