import { Col, Row } from 'antd';
import logo from './logo.png'

const LowerHeader = () => {
  return (
    <div className="container lower-header">
      <Row justify="space-between" align="middle">
        <Col lg={6} md={6} sm={24} xs={24}>
          <img src={logo} alt="ATJ" />
        </Col>
        <Col lg={18} md={18} sm={24} xs={24} className="actions">
          <a href="https://exporter.inspectionsapp.jp/">Exporter Login</a>
          <a href="/about-us">About Us</a>
          {/* <a href="https://www.autoterminalpsi.com/psifee">Fees</a>
          <a href="https://www.autoterminalpsi.com/contact">Contact</a> */}
          <a href="/privacy">Privacy Policy</a>
          <a href="/terms">Terms & Condition</a>
        </Col>
      </Row>
    </div>
  )
}

export default LowerHeader;