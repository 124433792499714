const PostOne = () => {
    return <div className="warnings">
        <div className="container">
            <h2 className="warning-title">URGENT WARNING REGARDING RISING FRAUDULENT CERTIFICATES FROM JAPAN TO JAMAICA
            </h2>
            <h4 className="warning-sub-title">7 November 2023</h4>
            <article>
                <p>The Japanese vehicle import, and export industry confronts an immediate and serious issue. The surge in fraudulent pre-shipment inspection certificates poses a grave threat, eroding trust and reliability within our sector and endangering importers, exporters, and the industry as a whole.</p>
                <p>We have uncovered multiple suppliers connected to fraudulent pre-shipment certificates, warranting the collective concern of industry stakeholders. Importers and exporters are strongly urged to exercise increased caution and vigilance when dealing with these suppliers listed below.</p>
                <ol className="supplier-list">
                    <li>
                        <h3>Supernova Japan</h3>
                        <div>
                            <p className="highlight-red"><span>Address	</span>: Ginga house 2F, 12-26, Kamitanoyumachi, Beppu ,Oita.</p>
                            <p className="highlight-red"><span>Email 	    </span>: info@supernovajapan.jp</p>
                            <p className="highlight-red"><span>Tel		</span>: 0977-85-8542</p>
                            <p className="highlight-red"><span>Fax		</span>: 0977-85-8543</p>
                            <p className="highlight-red"><span>Website	</span>: https://www.supernovajapan.jp/</p>
                        </div>
                    </li>
                    <li>
                        <h3>Muhammad Hamma - <span>Sales Agent of Supernova Japan.</span></h3>
                        <div>
                            <p className="highlight-red"><span>Email </span>: hanni.khan47@gmail.com</p>
                            <p>We have identified numerous fraudulent certificates for vehicles processed through Supernova.</p>
                        </div>
                    </li>
                </ol>
                <p>To address this alarming trend, ATJ is taking proactive measures continuously. We strongly urge all importers and exporters to verify the authenticity of pre-shipment inspection certificates through ATJ's Vehicle Status Tracking system or by scanning the original QR codes on each vehicle. This precautionary step offers robust protection against fraudulent certificates.</p>
                <p>We sincerely appreciate your cooperation and dedication to preserving our industry's integrity. If you have any information regarding fraudulent activities, please do not hesitate to report it to us. Our email address is <a href="mailto:hline@inspections.jp">hline@inspections.jp</a>  </p>
                <p>Together, let's ensure the continued strength and reliability of our sector.</p>
            </article>
        </div>
    </div>
}

export default PostOne;