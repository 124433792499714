import LowerHeader from './LowerHeader';
import Footer from './Footer';
import WarningsList from './WarningsList';

const WarningPage = () => {
    return <>
        <LowerHeader />
        <WarningsList />
        <Footer />
    </>
}

export default WarningPage;