import { Button, Col, Input, Row } from 'antd';
import { FiMapPin, FiPhone, FiPrinter, FiGlobe } from "react-icons/fi";
import { BiLogoFacebook , BiLogoTwitter, BiLogoYoutube} from 'react-icons/bi'

const Footer = () => {
  return (
    <>
      <div className="footer-details">
        <div className="container">
          <Row justify="space-between" align="top">
            <Col lg={8}>
              <div className="header-wrapper">
                <FiMapPin /> Address
              </div>
              <div className="content-wrapper">
                <p>ATJ Inspection Lines - Japan</p>
                <p>East (Main Office)</p>
                <p>1-17-69 Shiohama Kisarazu-shi Chiba 292-0838</p>
                <p>Japan</p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="header-wrapper">
                <FiPhone /> Telephone
              </div>
              <div className="content-wrapper">
                <p>+81-438-30-7488</p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="header-wrapper">
                <FiPrinter /> Fax
              </div>
              <div className="content-wrapper">
                <p>+81-438-30-7489</p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="header-wrapper">
                <FiGlobe /> Website
              </div>
              <div className="content-wrapper">
                <p>www.inspectionsapp.jp</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="footer-summary">
        <div className="container">
          <Row justify="space-between" align="middle">
            <Col lg={8} md={24}>
              <p>© Copyright 2024 inspectionsapp.jp</p>
            </Col>
            <Col lg={4} md={5}>
              <BiLogoFacebook />
              <BiLogoTwitter />
              <BiLogoYoutube />
            </Col>
            <Col lg={11} md={12}>
              <Input placeholder="Enter your email" /> <Button type="primary">Submit</Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Footer;