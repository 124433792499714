import React from 'react';
import logo from './logo.png'
import { Button, Col, Row } from 'antd';
// import { BiLogoFacebook, BiLogoTwitter, BiLogoYoutube } from 'react-icons/bi';

const Header = () => {
  return (
    <div className="container top-header">
      <Row justify="space-between" align="middle">
        <Col md={6}>
          <img src={logo} alt="ATJ" />
        </Col>
        <Col md={5} xs={3} className="actions">
          <Button size='middle' type='primary' onClick={() => {
            window.open("https://exporter.inspectionsapp.jp/", '_self');
          }}>Exporter Login</Button>
          {/* <a href="#" className="text-white hover:text-gray-300">
            <BiLogoFacebook />
          </a>
          <a href="#" className="text-white hover:text-gray-300">
            <BiLogoTwitter />
          </a>
          <a href="#" className="text-white hover:text-gray-300">
            <BiLogoYoutube />
          </a> */}
        </Col>
      </Row>
    </div>
  );
};

export default Header;
