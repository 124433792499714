const PostTwo = () => {
    return <div className="warnings">
        <div className="container">
            <h2 className="warning-title">Mandatory Pre-shipment Inspection for Used Vehicles Bound for Jamaica
            </h2>
            <h4 className="warning-sub-title">25th September 2023</h4>
            <article>
                <p>Dear valued customer,</p>
                <p>We would like to bring to your attention a critical matter concerning the export of used vehicles to Jamaica.</p>
                <p>Autoterminal Japan is the sole inspection company entrusted with conducting pre-shipment inspections for used vehicles destined for Jamaica. Each used vehicle intended for export to Jamaica must undergo this mandatory inspection process.</p>
                <p>Regrettably, we have recently uncovered instances where fraudulent certificates have been used. This is a grave concern that undermines the integrity of our inspection process and poses significant risks to all parties involved.</p>
                <p>We want to emphasize that there are no exceptions to this inspection requirement. It is an essential step in ensuring the safety and compliance of vehicles being exported to Jamaica. We are committed to upholding the highest standards of quality and transparency in this process.</p>
                <p>Furthermore, please be aware that any supplier found to have submitted fraudulent certificates will be subject to legal action. We take this matter very seriously and will pursue all necessary legal measures to protect the integrity of our inspection system. </p>
                <p>We urge you to cooperate fully with our inspection process and to ensure that all certificates provided are genuine and accurate. Compliance with these requirements is not only a legal obligation but also a crucial step in maintaining the trust and credibility of our operations. Also, if you have any information regarding such activities please do not hesitate to report it to us. Our email address is <a href="mailto:hline@inspections.jp">hline@inspections.jp</a></p>
                <p>If you have any questions or require further clarification regarding the pre-shipment inspection process, please contact us.</p>
            </article>
        </div>
    </div>
}

export default PostTwo;