import React from 'react'
import Header from '../Header'
import SubPageBanner from '../components/SubPageBanner'
import Company from '../components/about/Company'
import OurGoals from '../components/about/OurGoals'
import History from '../components/about/History'
import LowerHeader from '../LowerHeader'
import Footer from '../Footer'

export default function AboutUs() {
  return (
    <>
      <SubPageBanner title="About Us" />
      <div className='about-us container'>
        <Company />
        <OurGoals />
        <History />
      </div>
      <LowerHeader />
      <Footer />
    </>
  )
}
