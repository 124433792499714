import React from 'react'
import { Row, Col, Card, Timeline, Divider } from 'antd';

export default function Company() {
  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <h2 className='title'>Comapany</h2>
          <Divider />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: '40px' }}>
        <Col span={24}>
          <Row gutter={16}>
            <Col span={8}>
              <p>
                <strong>Company Name:</strong>
              </p>
              <p>
                Autoterminal Japan Ltd.
              </p>
            </Col>
            <Col span={8}>
              <p>
                <strong>CEO:</strong>
              </p>
              <p>
                Mamoru Fujie
              </p>
            </Col>
            <Col span={8}>
              <p>
                <strong>Capital:</strong>
              </p>
              <p>
                10 million yen
              </p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <p>
                <strong>Business Content:</strong>
              </p>
              <p>
                Automobile inspection business
              </p>
            </Col>
            <Col span={8}>
              <p>
                <strong>Establishment:</strong>
              </p>
              <p>
                March 1997
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
