import React from 'react'
import Header from '../Header'
import SubPageBanner from '../components/SubPageBanner'
import LowerHeader from '../LowerHeader'
import Footer from '../Footer'
import data from '../appData/privacy.json';
import { Col, Divider, Row } from 'antd'

export default function Privacy() {
  const { content } = data;

  const policyList = content.map(policies => {
    const { title, details, list } = policies;
    const detailsList = details.map(point => <p>{point}</p>);
    const listItems = list.map(item => <li>{item}</li>);

    return (
      <>
        <Row gutter={16}>
          <Col span={24}>
            <h2 className='title'>{title}</h2>
            <Divider />
          </Col>
        </Row>
        <Row gutter={16} style={listItems.length > 0 ? {} : { marginBottom: '20px' }}>
          <Col span={24}>
            {detailsList}
          </Col>
        </Row>
        {
          listItems.length > 0 && <Row gutter={16} style={{ marginBottom: '20px' }}>
            <Col span={24}>
              <ul>
                {listItems}
              </ul>
            </Col>
          </Row>
        }

      </>
    );
  });

  return (
    <>
      <SubPageBanner title="Privacy Policy" />
      <div className='privacy container'>
        {policyList}
      </div>
      <LowerHeader />
      <Footer />
    </>
  )
}
