import HowToTestImgage from "./how-to-test.jpeg";
import ScanQR from "./scan-qr.jpeg";


const PostThree = () => {
    return <div className="warnings">
        <div className="container">
            <h2 className="warning-title">Jamaica Tracking System and PSI Certificate with QR Code
            </h2>
            <h4 className="warning-sub-title">1st July 2020</h4>
            <article>
                <p>We are pleased to introduce our new Vehicle Status Tracking (VST) System which allows you to verify PSI inspection information including odometer mileage.</p>
                <p>It is our greatest concern to provide our customers with a reliable service. We believe our new VST system will prevent any post-inspection fraudulent acts such as odometer tampering and certificate forgery as you can now verify the inspection information simply by entering the chassis number in the VST which is accessible on our website, or reading the QR code that is now printed on the certificate.</p>
                <div className="warning-imgs-wrapper">
                    <img src={HowToTestImgage} />
                    <img src={ScanQR} />
                </div>
                <p>ATJ highly recommends that importers utilize our <a href="https://www.autoterminalpsi.com/" target="_blank">Vehicle Status Tracking system</a> before shipping their vehicles or scan the original QR code provided by ATJ on each vehicle using your mobile phone to view original certificate. This will allow you to conveniently verify the authenticity of the PSIC data within our system.</p>
                <p>Should you encounter any situation where the data is not registered in our system, it may raise concerns about the legitimacy of the PSIC. To address any doubts or suspicions, please do not hesitate to contact ATJ directly. You can reach us at <a href="mailto:hline@inspections.jp">hline@inspections.jp</a> at any time, or call us at <a href="tel:+81438307488">+81438307488</a> and we will promptly provide you with the correct and accurate information.</p>
                <p>Our commitment is to ensure the integrity of the process and the trust of our valued clients. We are here to support you and provide a transparent and reliable service.</p>
            </article>
        </div>
    </div>
}

export default PostThree;