import banner from './hero-slic.png'
import { Row, Col} from 'antd'
import Header from './Header';

const HeroBanner = ({ children }) => {
  return (
    <div className="hero-banner" style={{ backgroundImage: `url(${banner})`}}>
      <Header/>
        <div className="container">
          <Row justify="space-between" align="middle">
            <Col lg={12} md={12} sm={24}>
              <h2 className="banner-title">Check your vehicle status with us</h2>
              <div className="attention-wrapper">
                <span className="title">Attention!</span>
                <p>
                  ATJ inspection record is registered here at autoterminalpsi.com and inspectionsapp.jp only.
                  Beware the the fake information and/or fraud certificate confirmed with other domain.
                </p>
              </div>
            </Col>
            <Col lg={10} md={11} sm={24}>
              { children }
            </Col>
          </Row>
        </div>
    </div>
  )
}

export default HeroBanner