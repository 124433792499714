import LowerHeader from './LowerHeader';
import Footer from './Footer';
import PostTwo from './PostTwo';

const SecondWarning = () => {
    return <>
        <LowerHeader />
        <PostTwo />
        <Footer />
    </>
}

export default SecondWarning;