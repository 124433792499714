import banner from '../hero-slic.png'
import { Row, Col } from 'antd'
import Header from '../Header';

const SubPageBanner = ({ title, children }) => {
  return (
    <div className="hero-banner" style={{ backgroundImage: `url(${banner})` }}>
      <Header />
      <div className="container">
        <Row justify="center" align="middle">
          <Col lg={12} md={12} sm={24}>
            <h3 className="banner-title sub-title">{title}</h3>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SubPageBanner