import { useState } from 'react';
import { Modal } from 'antd';
import NoticeImage from '../download-app-banner.png';


const NoticeModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(true);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <Modal className='new-year-notice-modal' title="." open={isModalOpen} maskClosable={false} closable={true} onCancel={handleCancel} width={1000} footer={null} >
            <div className='new-year-notice-wrapper'>
                {/* TODO: Use proper image url & alt text when using this component */}
                <img src={NoticeImage} alt='App download Notice Image' />
            </div>
        </Modal>
    );
};

export default NoticeModal;