import { Button, Form, Radio, Input } from 'antd';

const TrackingForm = () => {
  const handleFinish = (data) => {
    window.location.href = `https://admin.inspectionsapp.jp/inspections/reports/track?d=${data.destination}&c=${data.chassisNo?.trim()}`;
  }

  return (
    <div className="tracking-form">
      <span className="title">Track Vehicle Inspections</span>

      <Form name="form_item_path" layout="vertical" onFinish={handleFinish}>
        <Form.Item label="Select Destination" name="destination" rules={[{ required: true, message: 'Destination is required' }]}>
          <Radio.Group>
            <Radio value="jamaica">Jamaica (PSI)</Radio>
            <Radio value="zambia">Zambia (RWI)</Radio>
            <Radio value="uganda">Uganda (UNBS)</Radio>
            <Radio value="odometer">Odometer</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Enter Chassis Number" name="chassisNo" rules={[{ required: true, message: 'Chassis number is required' }]}>
          <Input placeholder="CHS01234" />
        </Form.Item>

        <Button block type="primary" htmlType="submit">Check Vehicle Status</Button>
      </Form>


    </div>
  )
}

export default TrackingForm;