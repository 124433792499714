import LowerHeader from './LowerHeader';
import Footer from './Footer';
import PostOne from './PostOne';

const FirstWarning = () => {
    return <>
        <LowerHeader />
        <PostOne />
        <Footer />
    </>
}

export default FirstWarning;