import howItWorks from './how-it-works.png'

const HowItWorks = () => {
  return (
    <div className="how-it-works">
      <div className="container">
        <h2 className="banner-title">How it works</h2>
        <p className="moto">This website facilitates faster and better inspection process. Please let us know where you would like to create your account.</p>

        <img className="banner-img" src={howItWorks} alt="test"/>
      </div>
    </div>
  )
}

export default HowItWorks;